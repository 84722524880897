<template>
  <div class="Directlymenber">
    <div class="top-title">
      <div class="top-text">会员详情</div>
      
        <el-form ref="form" label-width="100px">
          <div class="top-box">
              <div class="top-my">
                  直推数:{{datarow.sontotal}}人
              </div>
              <div class="top-my">
                  佣金数:{{datarow.commission_income_total}}元
              </div>
              <div class="top-my">
                  总粉丝数:{{datarow.fanstotal}}人
              </div>
              <el-button
                type="primary"
                class="biy-icons" icon="el-icon-check" @click="upgrade()"
               v-if="memberLevelName.indexOf('超级会员') == 0?true:false"
                >升级成经理</el-button>
            </div>
        </el-form>
    </div>

    <!-- 会员选择 -->
    <div class="select">
          <div class="select-left">
              <div class="select-member active" @click="getSong()">
                  直属会员
              </div>
              <div class="select-member" @click="getSongfour()">
                  推荐会员
              </div>
          </div>

 <el-form ref="form" label-width="100px">
        <div class="select-form">
             <el-form-item label="排序" >
            <el-select v-model="seeks.d" placeholder="请选择"><el-option  v-for="(r, i) in seeklist.one" :key="i" :label="r.label" :value="r.value" @click.native="calculate()"></el-option></el-select>
          </el-form-item>

            <el-form-item label="筛选" >
            <el-select v-model="seeks.f" placeholder="请选择"><el-option  v-for="(r, i) in seeklist.two" :key="i" :label="r.label" :value="r.value" @click.native="calculates()"></el-option></el-select>
          </el-form-item>
        </div>
        </el-form>
    </div>  
    <!-- 选择列表  表格-->
    <div class="table-form">
      <el-table :data="list" style="width:90%" row-class-name="tableRowStyle" v-loading="loading">
        <el-table-column prop="true_name" label="姓名"> </el-table-column>
        <el-table-column prop="nickname" label="昵称"> </el-table-column>
        <el-table-column prop="bu_mobile" label="手机号"> </el-table-column>
        <el-table-column prop="commission" label="佣金数"> </el-table-column>
        <el-table-column prop="invited_fanstotal" label="粉丝数"> </el-table-column>
        <el-table-column prop="son_total" label="直推数"> </el-table-column>
      </el-table>
            <!-- 分页器 -->
      <div class="block" style="margin-top: 15px">
        <el-pagination
          align="center"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[10, 20]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
// 假数据
import fakelist from "@/api/fakelist.js"
import apiurl from "@/api/apiurl/apiurl.js"
import config from "@/request/config.js"

export default {
  data() {
    return {
            // 上传图片的地址
      upLoadUrl:config.ImgUrl,
      // 分页
      currentPage: 1, // 当前页码
      total: 10,
      pageSize: 10, // 每页的数据条数
      loading: this.$common.loading,
      
      // 下拉框
      seeks: { },
      seeklist:fakelist.directlymenber,

      // 数据
      datarow:{},
      list:[],
      threshold:'',

      memberLevelName:'',

      // 筛选
      saixuan:1,
    };
  },
  created() {
     this.getSong()
     this.getSongtwo()
     this.getSongthree()
  },
  mounted() {
			//选项卡
			$(function() {
				$(".select-member").click(function() {
					$(".select-member").removeClass("active");
					$(this).addClass("active");
				});
			});
  },

  methods: {
    //查询直属接口
      async getSong(){
        this.saixuan = 1;
				let res = await apiurl.fetchPageData({
      FOREND_TOKEN:this.$common.baseGetToken(),//统一传递一下前端token 
      loginName:this.$common.baseGetuSername(),//登录账号 
      loginType:this.$common.baseGetSeparate(),//区分用户和商家1用户2商家
        pageIndex: this.currentPage,
        pageSize: this.pageSize,
        bizCatalog:'WorkerEntity',
        handleMode:'fetchallInviteders',
        workerId:this.$route.params.id,
        orderby:this.seeks.d,
        level:'1',
        query:{
          memberLevel:this.seeks.f,
        }
        })
        console.log(res);
        if(this.$common.verifyAjaxResult(res,false)==false) return;
           this.list = res.Result.pageData
           this.total = res.Result.count
           this.loading = false
      },
    //查询推荐接口
      async getSongfour(){
        this.saixuan = 2;
				let res = await apiurl.fetchPageData({
      FOREND_TOKEN:this.$common.baseGetToken(),//统一传递一下前端token 
      loginName:this.$common.baseGetuSername(),//登录账号 
      loginType:this.$common.baseGetSeparate(),//区分用户和商家1用户2商家
        pageIndex: this.currentPage,
        pageSize: this.pageSize,
        bizCatalog:'WorkerEntity',
        handleMode:'fetchallInviteders',
        workerId:this.$route.params.id,
        orderby:this.seeks.d,
        level:'2',
        query:{
          memberLevel:this.seeks.f,
        }
        })
        if(this.$common.verifyAjaxResult(res,false)==false) return;
           this.list = res.Result.pageData
           this.total = res.Result.count
      },
      
      // 会员详情接口
      async getSongtwo(){
				let res = await apiurl.entityDataHandle({
        FOREND_TOKEN:this.$common.baseGetToken(),//统一传递一下前端token 
        loginName:this.$common.baseGetuSername(),//登录账号 
        loginType:this.$common.baseGetSeparate(),//区分用户和商家1用户2商家
        handleMode:'fetch',
        bizCatalog:'WorkerEntity',
        workerId:this.$route.params.id,
        })
        if(this.$common.verifyAjaxResult(res,false)==false) return;
          this.datarow = res.Result;
          this.memberLevelName = res.Result.memberLevelName
      },

          //升级规则
      async getSongthree(){
			let res = await apiurl.entityDataHandle({
      FOREND_TOKEN:this.$common.baseGetToken(),//统一传递一下前端token 
      loginName:this.$common.baseGetuSername(),//登录账号 
      loginType:this.$common.baseGetSeparate(),//区分用户和商家1用户2商家
        bizCatalog:'CommissionRuleEntity',
        handleMode:'fetchall',
         })
        if(this.$common.verifyAjaxResult(res,false)==false) return;
          this.threshold=res.Result.super.threshold
      },

      //弹窗
      upgrade(){
        if (this.datarow.fanstotal < this.threshold) {
          this.$confirm('如果达不到后台设置的超级会员数，点击申请成为超级会员按钮给与提示“申请成为超级会员， 需要拉取6个直属会员哦，请继续努力', '消息提示', {
          distinguishCancelAndClose: true,
          confirmButtonText: '确认',
        })
        }else{
          this.$confirm('是否确认升级', '消息提示', {
          distinguishCancelAndClose: true,
          confirmButtonText: '确认',
          cancelButtonText: '取消操作'
        }).then(() => {
          this.upgrades()
          }).catch(action => {
            console.log("取消");
          })
        }
          

      },
    // 升级成经理
   async upgrades(){
        let res = await apiurl.entityDataHandle({
          FOREND_TOKEN:this.$common.baseGetToken(),//统一传递一下前端token 
          loginName:this.$common.baseGetuSername(),//登录账号 
          loginType:this.$common.baseGetSeparate(),//区分用户和商家1用户2商家

        bizCatalog:'WorkerEntity',
        handleMode:'promoteBeManager',
        workerId:this.$route.params.id,
       })
        if(this.$common.verifyAjaxResult(res,false)==false) return;
         this.$alert('设置成功', '消息提示', {confirmButtonText: '确定'});
    },

        // 排序排序排序
        calculate(){
          this.currentPage = 1;
          this.saixuan == 1? this.getSong():this.getSongfour()
        },
        
        // 筛选排序排序
        calculates(){
          this.currentPage = 1;
          this.saixuan == 1? this.getSong():this.getSongfour()
        },

    // 分页 每页条数改变时触发 选择一页显示多少行
    handleSizeChange(val) {
      this.pageSize = val;
      this.getSong();
    },
    //当前页改变时触发 跳转其他页
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getSong();
    },

  },
  computed: {},
};
</script>


<style  scoped>
.Directlymenber .top-box{
 padding: 50px 0 0 50px;
}
.top-box .top-my{
  width: 26%;
  font-size: 16px;
 
}
.biy-icons {
    width: 150px;
    text-align: center;
    height: 36px;
    line-height: 36px;
    margin-left: 20px;
    padding: 0;
    background-color: #01847F;
  }

.select{
  width: 80%;
  height: 100px;
  padding-top: 50px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}

.select-left{
  width: 300px;
  display: flex;
  justify-content: space-between;
}
.select-left .select-member{
  height: 34px;
  font-size: 16px;
  cursor: pointer;
  transition: all .3s;
}
.select-left .select-member:hover{
  border-bottom: 2px solid red;
  color: red;
}
.select-left .select-member.active{
    border-bottom: 2px solid red;
    color: red;
}
.select-form{
  width: 500px;
  display: flex;
  justify-content: space-between
}
@import "../../assets/css/liststyle.css";
</style>